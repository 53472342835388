<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Регистрация</div>
    </f7-navbar>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="text"
        placeholder="Ваше имя"
        info="Введите Ваше полное имя"
        clear-button
        required
        validate
        :value="form.name"
        @input="form.name = $event.target.value"
      ></f7-list-input>
      <!--            <f7-list-input-->
      <!--                    type="email"-->
      <!--                    placeholder="Email"-->
      <!--                    info="Введите электронный адрес"-->
      <!--                    clear-button-->
      <!--                    required validate-->
      <!--                    :value="form.email"-->
      <!--                    @input="form.email = $event.target.value"-->
      <!--            ></f7-list-input>-->
      <f7-list-input
        type="tel"
        placeholder="Телефон"
        info="Ваш телефон пример (9998887766) без (+7, 8)"
        clear-button
        required
        validate
        max="10"
        pattern="[0-9]*"
        data-error-message="Введите Телефон!"
        :value="form.phone"
        @input="
          ($event) => {
            form.phone = $event.target.value;
            form.email = $event.target.value + '@mail.ru';
          }
        "
      ></f7-list-input>
      <f7-row>
        <f7-col width="50">
          <f7-list-input
            type="text"
            placeholder="*******"
            info="Введите код"
            pattern="[0-9]*"
            data-error-message="Введите Код!"
            clear-button
            required
            validate
            :value="form.password"
            @input="
              form.password = form.password_confirmation = $event.target.value
            "
          ></f7-list-input>
        </f7-col>
        <f7-col width="50">
          <f7-button
            id="sendSmsBtn"
            style="margin-right: 16px"
            big
            fill
            v-on:click="sendSms()"
            >Получить код</f7-button
          >
        </f7-col>
      </f7-row>
      <!--            <f7-list-input-->
      <!--                    type="email"-->
      <!--                    placeholder="Email"-->
      <!--                    info="Введите электронный адрес"-->
      <!--                    clear-button-->
      <!--                    required validate-->
      <!--                    :value="form.email"-->
      <!--                    @input="form.email = $event.target.value"-->
      <!--            ></f7-list-input>-->
      <!--            <f7-list-input-->
      <!--                    type="text"-->
      <!--                    placeholder="Гос. Номер"-->
      <!--                    info="Введите гос. номер Вашего авто"-->
      <!--                    clear-button-->
      <!--                    required validate-->
      <!--                    :value="form.state_number"-->
      <!--                    @input="form.state_number = $event.target.value"-->
      <!--            ></f7-list-input>-->

      <!--            <f7-list-item title="Марка" smart-select :smart-select-params="{openIn: 'popup', searchbar: true,-->
      <!--            searchbarPlaceholder: 'Поиск по маркам авто'}">-->
      <!--                <select name="car" v-on:change="set_mark($event)">-->
      <!--                    <optgroup label="Марки авто">-->
      <!--                        <option v-for="mark of marks" :value="mark.value">{{mark.label}}</option>-->
      <!--                    </optgroup>-->
      <!--                </select>-->
      <!--            </f7-list-item>-->

      <!--            <f7-list-item title="Модель" smart-select :smart-select-params="{openIn: 'popup', searchbar: true,-->
      <!--            searchbarPlaceholder: 'Поиск по модели авто'}">-->
      <!--                <select name="car" v-on:change="set_model($event)">-->
      <!--                    <optgroup label="Модели авто">-->
      <!--                        <option v-for="model of models" :value="model.value">{{model.label}}</option>-->
      <!--                    </optgroup>-->
      <!--                </select>-->
      <!--            </f7-list-item>-->

      <!--            <f7-list-input-->
      <!--                    type="password"-->
      <!--                    placeholder="Пароль"-->
      <!--                    info="Пароль должен быть не менее 6 символов"-->
      <!--                    clear-button-->
      <!--                    required validate-->
      <!--                    :value="form.password"-->
      <!--                    @input="form.password = $event.target.value"-->
      <!--            ></f7-list-input>-->
      <!--            <f7-list-input-->
      <!--                    type="password"-->
      <!--                    placeholder="Повторите пароль"-->
      <!--                    info="Введите повторно пароль"-->
      <!--                    clear-button-->
      <!--                    required validate-->
      <!--                    :value="form.password_confirmation"-->
      <!--                    @input="form.password_confirmation = $event.target.value"-->
      <!--            ></f7-list-input>-->
    </f7-list>
    <f7-block>
      <f7-row tag="p">
        <f7-col tag="span">
          <f7-button big fill v-on:click="submit_register(form)"
            >Зарегистрироваться</f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
import Vue from "vue";
import store from "../../vuex/storage.js";

export default {
  name: "register",
  data() {
    return {
      form: {
        name: "",
        email: "mail@m.ru",
        phone: "",
        password: "",
        state_number: "А000АА25",
        car_model_id: "1",
        password_confirmation: "",
      },
      marks: {},
      models: {},
      countSendSms: 1,
      password_sms: null,
      code: null,
    };
  },
  created() {
    ApiService.getAutoMarks().then((res) => {
      this.marks = res;
      //console.log(this.marks);
    });
  },
  methods: {
    set_mark(ev) {
      let id = ev.srcElement.value;
      ApiService.getAutoModels(id).then((res) => {
        this.models = res;
        console.log(this.models);
      });
    },
    set_model(ev) {
      this.form.car_model_id = ev.srcElement.value;
    },
    submit_register(form_data) {
      let form_data_new = { ...form_data };
      form_data_new.phone = form_data_new.phone.replace(/[^+\d]/g, "");
      if (form_data_new.phone.length > 10) {
        this.$f7.dialog.confirm(
          " Введен не верный номер телефона телефон должен быть без пробелов а так же без восьмерки " +
            "пример (9998887766)",
          "Телефон"
        );
        return 0;
      }
      if (!(this.password_sms == this.form.password)) {
        this.$f7.dialog.confirm(
          "Кода подтверждения",
          "Не верный код подтверждения."
        );
        return 0;
      }
      this.$f7.dialog.preloader("Пожалуйста подождите");
      form_data_new.phone = "7" + form_data_new.phone;
      ApiService.submitRegistration(form_data_new)
        .then((res) => {
          if (res.errors != null) {
            this.$f7.dialog.close();
            let errorsString = "";
            res.errors.forEach((item) => {
              errorsString += item + "\n";
            });
            this.$f7.dialog.alert(errorsString, "Ошибка");
          } else {
            ApiService.login(form_data_new.phone, this.form.password)
              .then(() => {
                this.$f7.dialog.close();
                this.$f7router.navigate("/", { reloadAll: true });
                store.dispatch("chancgeLoginStatus");
              })
              .catch((error) => {
                this.$f7.dialog.close();
                console.log(form_data_new.phone, this.form.password);
                if (error.status == 401) {
                  this.$f7.dialog.alert("Неверный логин или пароль", "Ошибка");
                }
              });
          }
        })
        .catch(() => {
          this.$f7.dialog.close();
          this.$f7.dialog.alert("Введеные данные не корректные!", "Ошибка");
        });
    },
    sendSms() {
      if (this.countSendSms >= 3) {
        this.$f7.dialog.confirm(
          "Ошибка отправки",
          "Здравствуйте, если Вам не приходит СМС обратитесь к администратору!"
        );
      } else {
        document.getElementById("sendSmsBtn").classList.add("disabled");
        this.$f7.dialog.alert("СМС сообщение отправленно.", "Ожидайте");
        this.password_sms =
          Math.floor(Math.random() * (999999 - 100000 + 100000)) + 100000;
        console.log("this.state.password_sms", this.password_sms);
        let phone = this.form.phone.replace(/[^+\d]/g, "");
        console.log(phone);
        Vue.axios
          .get(
            "https://smsc.ru/sys/send.php?login=tubeCityRu&psw=10203040&phones=7" +
              phone +
              `&mes=Ваш код подтверждения: ${this.password_sms}`
          )
          .then(() => {})
          .catch((e) => {
            console.log(e);
          });
        this.countSendSms += 1;
        console.log(this?.countSendSms);
      }
    },
  },
};
</script>

<style scoped>
.list li {
  list-style-type: none;
}
</style>
