<template>
  <f7-page :page-content="addOrdersExecuter == true ? true : false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Режим исполнителя</div>
      <f7-nav-right>
        <f7-link
          v-if="addOrdersExecuter == false"
          v-on:click="onAddBecomeContractor"
          class="searchbar-enable"
          icon="fa fa-check"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block v-if="addOrdersExecuter == true" strong>
      <f7-card :outline="false" :padding="false">
        <f7-card-content>
          <div class="content-block text-center">
            <div class="row">
              <div class="btn-group selling-choice row" data-toggle="buttons">
                <label
                  v-on:click="onTypeFace(1)"
                  style="margin-left: auto; margin-right: auto"
                  class="btn btn-default fiz-btn col-80 type-user"
                  data-type="physes"
                >
                  <f7-preloader></f7-preloader>
                  <strong>Заявка отправленна на модерацию</strong>
                </label>
              </div>
            </div>
          </div>
        </f7-card-content>
        <f7-card-content>
          <f7-button v-on:click="updateStatus()" raised fill
            >обновить страницу</f7-button
          >
        </f7-card-content>
      </f7-card>
    </f7-block>
    <f7-toolbar v-if="addOrdersExecuter == false" tabbar>
      <f7-link tab-link="#mode-execute-tab-1" tab-link-active>Данные</f7-link>
      <f7-link tab-link="#mode-execute-tab-2">Виды</f7-link>
      <f7-link tab-link="#mode-execute-tab-3">Фото</f7-link>
    </f7-toolbar>
    <f7-tabs v-if="addOrdersExecuter == false" animated>
      <f7-tab id="mode-execute-tab-1" class="page-content" tab-active>
        <f7-block>
          <f7-block-title>Данные исполнителя</f7-block-title>
          <f7-card :outline="false" :padding="false">
            <f7-card-content>
              <div class="content-block text-center">
                <div class="row">
                  <div
                    class="btn-group selling-choice row"
                    data-toggle="buttons"
                  >
                    <label
                      v-on:click="onTypeFace(1)"
                      class="btn btn-default fiz-btn col-50 type-user"
                      data-type="physes"
                    >
                      <f7-icon slot="media" icon="fa fa-user"></f7-icon
                      ><strong>Физическое лицо</strong>
                    </label>
                    <label
                      v-on:click="onTypeFace(2)"
                      class="btn btn-default yur-btn col-50 type-user"
                      data-type="jurs"
                    >
                      <f7-icon slot="media" icon="fa fa-users"></f7-icon
                      ><strong>Юридическое лицо</strong>
                    </label>
                  </div>
                </div>
              </div>
            </f7-card-content>
          </f7-card>
          <f7-card v-if="becomeContractor.type_face == 1">
            <f7-card-header>
              <f7-block-title>Физическое лицо</f7-block-title>
            </f7-card-header>
            <f7-card-content>
              <f7-list no-hairlines-md>
                <f7-list-input
                  type="text"
                  placeholder="Серия и номер паспорта*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.passport_number"
                  @input="
                    becomeContractor.passport_number = $event.target.value
                  "
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Кем выдан паспорт*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.passport_issued"
                  @input="
                    becomeContractor.passport_issued = $event.target.value
                  "
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Год рождения*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.year_birth"
                  @input="becomeContractor.year_birth = $event.target.value"
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Адрес прописки*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.place_residence"
                  @input="
                    becomeContractor.place_residence = $event.target.value
                  "
                >
                </f7-list-input>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-card v-if="becomeContractor.type_face == 2">
            <f7-card-header>
              <f7-block-title>Юридическое лицо</f7-block-title>
            </f7-card-header>
            <f7-card-content>
              <f7-list no-hairlines-md>
                <f7-list-input
                  type="text"
                  placeholder="Название организации*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.organization_names"
                  @input="
                    becomeContractor.organization_names = $event.target.value
                  "
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Тип организации*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.type_organization"
                  @input="
                    becomeContractor.type_organization = $event.target.value
                  "
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Код ИНН*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.ogrn_code"
                  @input="becomeContractor.ogrn_code = $event.target.value"
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Код ОГРН*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.tin_code"
                  @input="becomeContractor.tin_code = $event.target.value"
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Юридический адрес*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.legal_address"
                  @input="becomeContractor.legal_address = $event.target.value"
                >
                </f7-list-input>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-card v-if="becomeContractor.type_face != 0">
            <f7-card-header>
              <f7-block-title>Личные данные</f7-block-title>
            </f7-card-header>
            <f7-card-content>
              <f7-list no-hairlines-md>
                <f7-list-input
                  type="text"
                  placeholder="Имя*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.f_name"
                  @input="becomeContractor.f_name = $event.target.value"
                >
                </f7-list-input>
                <f7-list-input
                  type="text"
                  placeholder="Фамилия*"
                  clear-button
                  validate
                  required
                  :value="becomeContractor.l_name"
                  @input="becomeContractor.l_name = $event.target.value"
                >
                </f7-list-input>
                <f7-list-input
                  label="Город*"
                  type="select"
                  defaultValue="0"
                  :value="becomeContractor.city"
                  @input="becomeContractor.city = $event.target.value"
                  placeholder="Пожалуйста, выберите..."
                >
                  <option value="0">Выберите город</option>
                  <template v-if="city.length !== 0">
                    <option
                      v-for="cityItem in city"
                      :value="cityItem.value"
                      :key="cityItem"
                    >
                      {{ cityItem.label }}
                    </option>
                  </template>
                </f7-list-input>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-button v-on:click="tabServices" raised fill>Далее</f7-button>
        </f7-block>
      </f7-tab>
      <f7-tab id="mode-execute-tab-2" class="page-content">
        <f7-block>
          <f7-block-title>Виды деятельности</f7-block-title>
          <div class="content-block">
            <form class="services-edit">
              <div class="list">
                <ul>
                  <li v-for="service in services" :key="service">
                    <label class="item-checkbox item-content">
                      <input
                        type="checkbox"
                        ternary
                        v-model="service.checked"
                      />
                      <i class="icon icon-checkbox"></i>
                      <div class="item-inner">
                        <div class="item-title">{{ service.name }}</div>
                      </div>
                    </label>
                  </li>
                </ul>
                <f7-button v-on:click="tabPhoto" raised fill>Далее</f7-button>
              </div>
            </form>
          </div>
        </f7-block>
      </f7-tab>
      <f7-tab id="mode-execute-tab-3" class="page-content">
        <f7-block>
          <div class="list">
            <div class="margin-bottom-15 content-block">
              <button
                v-on:click="onAddBecomeContractor"
                class="button button-fill color-deeporange text-thiny"
              >
                Отправить заявку
              </button>
            </div>
            <ul>
              <li>
                <div class="featured-image-block">
                  <img
                    src="../../img/camera.png"
                    alt=""
                    class="property-icon camera"
                  />
                  <h3>Загрузить фото документов</h3>
                  <input
                    type="file"
                    ref="file"
                    :name="uploadFieldName"
                    @change="
                      onFileChange($event.target.name, $event.target.files)
                    "
                    style="display: none"
                  />
                  <label
                    v-if="images.length == 0"
                    @click="launchFilePicker()"
                    class="image-upload-btn"
                  >
                    <img
                      src="../../img/upload.png"
                      alt=""
                      class="image-upload-icon"
                    />Загрузить фото
                  </label>
                  <f7-card v-for="image in images" :key="image">
                    <f7-card-content>
                      <img :src="getPrev(image)" width="100%" />
                    </f7-card-content>
                    <f7-card-footer class="no-border">
                      <f7-link v-on:click="dellPhoto()">Удалить</f7-link>
                    </f7-card-footer>
                  </f7-card>
                </div>
              </li>
            </ul>
          </div>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";

export default {
  name: "modeExecute",
  data: () => {
    return {
      addOrdersExecuter: false,
      services: [
        // {id:1, name:'Камеры', checked: false},
        { id: 2, name: "Экстренная помощь", checked: false },
        { id: 3, name: "Юридические услуги", checked: false },
        { id: 4, name: "Детали кузова", checked: false },
        { id: 5, name: "Шиномонтаж", checked: false },
        { id: 6, name: "Техпомощь", checked: false },
        { id: 7, name: "Эвакуатор", checked: false },
        { id: 8, name: "Аварийный комиссар", checked: false },
        { id: 9, name: "Диагностика авто", checked: false },
      ],
      city: [],
      images: [],
      errorDialog: null,
      errorText: "",
      uploadFieldName: "file",
      maxSize: 2048,
      becomeContractor: {
        type_face: 0,
        passport_number: "",
        passport_issued: "",
        year_birth: "",
        organization_names: "",
        type_organization: "",
        ogrn_code: "",
        tin_code: "",
        legal_address: "",
        f_name: "",
        l_name: "",
        place_residence: "",
        city: "0",
      },
    };
  },
  created: function () {
    if (ApiService.user().status == null) {
      this.$f7.dialog.preloader("Пожалуйста подождите");
      ApiService.getCity()
        .then(async (response) => {
          const service = await ApiService.getService();
          this.services = service.map((v) => {
            return { ...v, checked: false };
          });
          console.log(response);
          this.city = response;
          this.$f7.dialog.close();
        })
        .catch(() => {
          this.$f7.dialog.close();
        });
    } else {
      this.addOrdersExecuter = true;
    }
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    getPrev(imageFile) {
      return URL.createObjectURL(imageFile);
    },
    updateStatus() {
      this.$f7.dialog.preloader("Пожалуйста подождите");
      ApiService.getUserData()
        .then((response) => {
          this.$f7.dialog.close();
          if (response.user.status == 2) {
            ApiService.setLocalStorageUser(response.user);
            this.$f7.dialog.alert(
              "Ваша заявка подтверждена. Приложения перезагрузиться",
              "Уведомления",
              () => {
                window.location.reload();
              }
            );
          }
        })
        .catch(() => {
          this.$f7.dialog.close();
        });
    },
    onFileChange(fieldName, file) {
      const { maxSize } = this;
      let imageFile = file[0];
      if (file.length > 0) {
        console.log("size" + imageFile.size / 10240);
        if (!imageFile.type.match("image.*")) {
          this.errorDialog = true;
          this.$f7.dialog.alert(
            "Пожалуйста, выберите файл изображения",
            "Ошибка"
          );
        } else if (imageFile.size / 10240 > maxSize) {
          this.$f7.dialog.alert(
            "Ваш файл слишком большой! Пожалуйста, выберите изображение до 10 МБ",
            "Ошибка"
          );
        } else {
          // Append file into FormData & turn file into image URL
          console.log(URL.createObjectURL(imageFile));
          this.images.push(imageFile);
          // let formData = new FormData()
          // let imageURL = URL.createObjectURL(imageFile)
          // formData.append(fieldName, imageFile)
          // // Emit FormData & image URL to the parent component
          // this.$emit('input', { formData, imageURL })
        }
      }
    },
    dellPhoto() {
      this.images = [];
    },
    onTypeFace(type) {
      this.becomeContractor.type_face = type;
    },
    onAddBecomeContractor() {
      if (this.validateFile()) {
        if (this.validateServices()) {
          if (this.validateUserData()) {
            let formData = new FormData();
            let services = [];
            this.services.forEach((value) => {
              if (value.checked == true) services.push(value.id);
            });
            for (var key in this.becomeContractor) {
              formData.append(key, this.becomeContractor[key]);
            }
            formData.append("avatar", this.images[0]);
            formData.append("services", JSON.stringify(services));
            this.$f7.dialog.preloader("Пожалуйста подождите");

            ApiService.addBecomeContractor(formData)
              .then((response) => {
                this.$f7.dialog.close();
                if (response == 1) {
                  this.$f7.dialog.alert(
                    "Заявка отправлена на модерацию ожидайте ответа",
                    "Уведомления",
                    () => {
                      this.addOrdersExecuter = true;
                    }
                  );
                }
              })
              .catch(() => {
                this.$f7.dialog.close();
              });
          }
        }
      }
    },
    tabPhoto() {
      if (this.validateServices()) {
        this.$f7.tab.show("#mode-execute-tab-3");
      }
    },
    tabServices() {
      if (this.validateUserData()) {
        this.$f7.tab.show("#mode-execute-tab-2");
      }
    },
    validateFile() {
      if (this.images.length == 0) {
        this.$f7.dialog.alert(
          "Необходимо загрузить фотографию документа",
          "Ошибка"
        );
        return false;
      }
      return true;
    },
    validateServices() {
      let check = false;
      this.services.forEach((value) => {
        if (check == false) check = value.checked;
      });
      if (check == false)
        this.$f7.dialog.alert(
          "Необходимо выбрать хотябы один вид деятельности",
          "Ошибка"
        );
      return check;
    },
    validateUserData() {
      if (this.becomeContractor.type_face == 0) {
        this.$f7.dialog.alert(
          "Выберите тип исполнителя (Физическое лицо/Юридическое лицо)",
          "Ошибка"
        );
        return false;
      } else if (
        this.becomeContractor.type_face == 1 &&
        (this.becomeContractor.passport_number.length == 0 ||
          this.becomeContractor.passport_issued.length == 0 ||
          this.becomeContractor.place_residence.length == 0 ||
          this.becomeContractor.year_birth.length == 0)
      ) {
        this.$f7.dialog.alert(
          "Все поля для Физического лица обязательны для заполнения",
          "Ошибка"
        );
        return false;
      } else if (
        this.becomeContractor.type_face == 2 &&
        (this.becomeContractor.organization_names.length == 0 ||
          this.becomeContractor.type_organization.length == 0 ||
          this.becomeContractor.ogrn_code.length == 0 ||
          this.becomeContractor.tin_code.length == 0 ||
          this.becomeContractor.legal_address.length == 0)
      ) {
        this.$f7.dialog.alert(
          "Все поля для Юридического лица обязательны для заполнения",
          "Ошибка"
        );
        return false;
      } else if (
        this.becomeContractor.city == "0" ||
        this.becomeContractor.l_name.length == 0 ||
        this.becomeContractor.f_name.length == 0
      ) {
        this.$f7.dialog.alert(
          "Все поля для Личьных данных обязательны для заполнения",
          "Ошибка"
        );
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped></style>
