<template>
  <f7-page>
    <f7-navbar :title="new_item.title" back-link="Back" sliding></f7-navbar>
    <f7-card class="demo-facebook-card">
      <f7-card-content>
        <h5>{{ new_item.title }}</h5>
        <img :src="url + new_item.img" width="100%" />
        <p class="likes" v-html="new_item.text"></p>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
export default {
  name: "blog-read",
  props: ["navigate"],
  data() {
    return {
      new_item: {},
      url: "http://tube-city.ru/img/post/",
    };
  },
  created() {
    ApiService.getNew(this.navigate).then((res) => {
      this.new_item = res;
      console.log(this.new_item);
    });
  },
  methods: {},
};
</script>

<style scoped>
</style>