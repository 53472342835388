import AboutPage from "./assets/vue/pages/about.vue";
import FormPage from "./assets/vue/pages/form.vue";
import DynamicRoutePage from "./assets/vue/pages/dynamic-route.vue";

import PanelLeftPage from "./assets/vue/component/panel-left.vue";
import ColorThemes from "./assets/vue/pages/color-themes.vue";
import Chat from "./assets/vue/pages/chat.vue";
import Vuex from "./assets/vue/pages/vuex.vue";
import faqs from "./assets/vue/pages/faqs.vue";
import calculator from "./assets/vue/pages/calculator";
import blog from "./assets/vue/pages/blog.vue";
import index from "./assets/vue/pages/index.vue";
import selectMap from "./assets/vue/pages/selectMap.vue";
import login from "./assets/vue/pages/login.vue";
import cameras from "./assets/vue/pages/cameras.vue";
import modeExecute from "./assets/vue/pages/modeExecute.vue";
import preview from "./assets/vue/pages/preview.vue";
import logout from "./assets/vue/pages/logout.vue";

import blogread from "./assets/vue/pages/blog-read.vue";

import register from "./assets/vue/pages/register.vue";
import resetPass from "./assets/vue/pages/resetPass.vue";
import myOrders from "./assets/vue/pages/MyOrders.vue";
import cameraDetails from "./assets/vue/pages/cameraDetails.vue";
import index_tutorial_1 from "./assets/vue/pages/index_tutorial_1.vue";
import index_tutorial_2 from "./assets/vue/pages/index_tutorial_2.vue";
import index_tutorial_3 from "./assets/vue/pages/index_tutorial_3.vue";
import mapExecutor from "./assets/vue/pages/mapExecutor.vue";
import payPage from "./assets/vue/pages/payPage.vue";
import ApiService from "./assets/vue/common/api.service.js";
import userProfile from "./assets/vue/pages/userProfile.vue";
import allOrders from "./assets/vue/pages/allOrders.vue";
import acceptedOrders from "./assets/vue/pages/acceptedOrders.vue";

export default [
  {
    path: "/about/",
    component: AboutPage,
  },
  {
    path: "/acceptedOrders/",
    component: acceptedOrders,
  },
  {
    path: "/form/",
    component: FormPage,
  },
  {
    path: "/dynamic-route/blog/:blogId/post/:postId/",
    component: DynamicRoutePage,
  },
  {
    path: "/panel-left/",
    component: PanelLeftPage,
  },
  {
    path: "/color-themes/",
    component: ColorThemes,
  },
  {
    path: "/chat/",
    component: Chat,
  },
  {
    path: "/vuex/",
    component: Vuex,
  },
  {
    path: "/faqs/",
    component: faqs,
  },
  {
    path: "/calculator/",
    component: calculator,
  },
  {
    path: "/all-orders/",
    component: allOrders,
  },
  {
    path: "/blog/",
    component: blog,
  },
  {
    path: "/blog-details/",
    component: blogread,
  },
  {
    path: "/user-profile/",
    component: userProfile,
  },
  {
    path: "/",
    async(routeTo, routeFrom, resolve) {
      console.log(ApiService.checkUser());
      if (ApiService.checkUser()) {
        resolve({ component: index });
      } else {
        resolve({ component: preview });
      }
    },
  },
  {
    path: "/index_tutorial_1/",
    component: index_tutorial_1,
  },
  {
    path: "/index_tutorial_2/",
    component: index_tutorial_2,
  },
  {
    path: "/index_tutorial_3/",
    component: index_tutorial_3,
  },
  {
    path: "/select-map/",
    component: selectMap,
  },
  {
    path: "/login/",
    component: login,
  },
  {
    path: "/cameras/",
    component: cameras,
  },
  {
    path: "/camera-details/",
    component: cameraDetails,
  },
  {
    path: "/mode-execute/",
    component: modeExecute,
  },
  {
    path: "/logout/",
    component: logout,
  },
  {
    path: "/register",
    component: register,
  },
  {
    path: "/reset",
    component: resetPass,
  },
  {
    path: "/my-orders",
    component: myOrders,
  },
  {
    path: "/map-executor",
    component: mapExecutor,
  },
  {
    path: "/pay",
    component: payPage,
  },
];
