<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link v-on:click="backBtn()" icon="icon icon-back"></f7-link>
      </f7-nav-left>
      <div class="title">Оплата сбербанк</div>
    </f7-navbar>
    <iframe width="100%" height="100%" :src="url"></iframe>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";

export default {
  name: "payPage",
  props: ["cash"],
  data: () => {
    return { url: "" };
  },
  created: function () {
    ApiService.userPayUrl(this.cash)
      .then((response) => {
        this.url = response;
      })
      .catch(() => {});
  },
  methods: {
    backBtn: function () {
      this.$f7router.navigate("/user-profile/");
    },
  },
};
</script>

<style scoped>
</style>
