<template>
  <f7-page>
    <div class="page-content" id="app-cover">
      <div class="pull-left">
        <a class="panel-open main-menu" href="#"
          ><i class="icon icon-bars"></i
        ></a>
      </div>
      <div class="clearfix"></div>
      <div class="app-cover-content">
        <div class="margin-bottom-10">
          <img
            src="../../img/tublogo.png"
            alt="Carlitos"
            width="100"
            height="100"
          />
        </div>
        <div class="text-large margin-bottom-15">
          Агрегатор автоуслуг «Tube-City» - ваш верный и профессиональный
          помощник на дороге!
        </div>
        <div class="margin-bottom-50">
          Спустило колесо, сел аккумулятор, кончилось топливо? А может, попали в
          ДТП или остановил патруль ДПС? Автомастера, юристы, страховщики
          помогут решить неприятные ситуации.
        </div>
        <div class="row">
          <div class="col-50">
            <!--<a href="/login/" style="background: #0aaaa0!important; color: #fff!important;" class="button button-fill color-white black-text text-thiny">Вход</a>-->
            <!--            <f7-button v-on:click="sendMsgToFlutter()" fill class="color-green">Test</f7-button>-->
          </div>
          <div class="col-50">
            <f7-button v-on:click="next()" fill class="color-green"
              >Далее</f7-button
            >
            <!--<a  class="button button-fill color-green text-thiny">Далее</a>-->
          </div>
        </div>
        <div class="row offset-top-10 margin-bottom-10">
          <div class="col-100">
            <a
              href="tel:+7(908)440-49-11"
              class="
                external
                button button-fill
                color-blue
                white-text
                text-thiny
              "
              style="background: #e96c4c !important"
            >
              Заказать сейчас
            </a>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
export default {
  name: "preview",
  methods: {
    next() {
      this.$f7router.navigate("/index_tutorial_1/");
    },
    // sendMsgToFlutter() {
    //   window.Toaster.postMessage('Hello World being called from Javascript code');
    // },
  },
};
</script>

<style scoped>
</style>
