<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Восстановить пароль</div>
    </f7-navbar>
    <f7-block-title>Восстановить пароль</f7-block-title>
    <f7-block strong>
      <f7-list no-hairlines-md>
        <f7-list-input
          label="Телефон"
          type="text"
          placeholder="Введите телефон"
          info="Ваш телефон пример (9998887766) без (+7, 8)"
          :value="user.phone"
          @input="user.phone = $event.target.value"
          clear-button
        >
          <f7-icon icon="flaticon-email" slot="media"></f7-icon>
        </f7-list-input>
      </f7-list>
      <f7-button v-on:click="checkForm" raised fill>Восстановить</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
import Vue from "vue";

export default {
  name: "resetPass",
  data: () => {
    return {
      user: {
        phone: "",
        pass: "",
      },
      countSendSms: 1,
      password_sms: null,
    };
  },
  created() {
    // console.log(ApiService.user());
    console.log(this.countSendSms);
  },
  methods: {
    checkForm: function () {
      this.$f7.dialog.preloader(
        "Пожалуйста подождите." + "Вам будет выслан новый пароль."
      );
      this.sendSms();
    },
    sendSms() {
      let phone = this.user.phone.replace(/[^+\d]/g, "");
      if (phone.length !== 10) {
        this.$f7.dialog.close();
        this.$f7.dialog.alert(
          " Введен не верный номер телефона телефон должен быть без пробелов а так же без восьмерки " +
            "пример (9998887766)",
          "Телефон"
        );
        return 0;
      }
      if (this.countSendSms >= 3) {
        this.$f7.dialog.close();
        this.$f7.dialog.alert(
          "Ошибка отправки",
          "Здравствуйте, если Вам не приходит СМС обратитесь к администратору!"
        );
      } else {
        this.password_sms =
          Math.floor(Math.random() * (999999 - 100000 + 100000)) + 100000;
        this.user.pass = this.password_sms;
        this.user.phone = "7" + phone;

        // TODO: FIXME: fix sms to Megafon
        // (async () => {
        //   console.log(
        //     this.user.phone,
        //     this.user.pass,
        //     await this.sendSmsRequest(phone)
        //   );
        // })();

        this.sendSmsRequest(phone);

        // reset password request
        ApiService.resetPass(this.user)
          .then(() => {
            this.$f7.dialog.close();
            this.$f7router.navigate("/login/");
          })
          .catch((error) => {
            this.$f7.dialog.close();
            if (error.status == 401) {
              this.$f7.dialog.alert("Неверный логин или пароль", "Ошибка");
            }
          });
        this.countSendSms += 1;
        // console.log(this.state.countSendSms);
      }
    },
    async sendSmsRequest(phone) {
      return await Vue.axios.get(
        `https://smsc.ru/sys/send.php?login=tubeCityRu&psw=10203040&phones=7${phone}&mes=Ваш код подтверждения: ${this.password_sms}`
      );
    },
  },
};
</script>

<style scoped></style>
