<template>
  <div>Выход</div>
</template>

<script>
import ApiService from "../common/api.service";
import store from "../../vuex/storage.js";

export default {
  name: "logout",
  created() {
    this.$f7.dialog.confirm(
      "Вы действительно хотите выйти?",
      "Выход",
      () =>
        ApiService.logout().then(() => {
          console.debug(ApiService.auth.user);
          this.$f7router.navigate("/", { reloadAll: true });
          this.$forceUpdate();
          store.dispatch("chancgeLoginStatus");
        }),
      () => this.$f7router.navigate("/", { reloadAll: true })
    );
  },
};
</script>

<style scoped></style>
