import { render, staticRenderFns } from "./cameraDetails.vue?vue&type=template&id=60002938&scoped=true&"
import script from "./cameraDetails.vue?vue&type=script&lang=js&"
export * from "./cameraDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60002938",
  null
  
)

export default component.exports