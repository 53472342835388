<template>
  <f7-page>
    <f7-navbar title="Видео" back-link="Back" sliding></f7-navbar>
    <iframe :src="video" width="100%" height="100%" frameborder="0"></iframe>
  </f7-page>
</template>

<script>
export default {
  name: "camera-details",
  props: ["video"],
  created() {
    console.log(this.video);
  },
};
</script>

<style scoped>
</style>