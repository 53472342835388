import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    isLoginIn: false,
  },

  actions: {
    userLogged({ commit }, user) {
      commit("USER_LOGGED", user);
    },
    chancgeLoginStatus({ commit }) {
      commit("IS_LOGIN_IN");
      console.log("chancgeLoginStatus");
    },
  },

  mutations: {
    USER_LOGGED(state, user) {
      state.user = user;
    },
    IS_LOGIN_IN(state) {
      state.isLoginIn = !state.isLoginIn;
      console.log("IS_LOGIN_IN", state.isLoginIn);
    },
  },
});
