import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

import Framework7 from "framework7/framework7.esm.bundle.js";

// Import Framework7 Vue
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";
// Import F7 Style
// eslint-disable-next-line no-unused-vars
import Framework7CSS from "framework7/css/framework7.css";

// Import F7 iOS Icons
// eslint-disable-next-line no-unused-vars
import Framework7Icons from "framework7-icons/css/framework7-icons.css";

// Import Material Icons
// eslint-disable-next-line no-unused-vars
import MaterialIcons from "material-design-icons/iconfont/material-icons.css";

// Import Fontawesome Theme Styles
// eslint-disable-next-line no-unused-vars
import FontAwesome from "font-awesome/css/font-awesome.css";

// Import App Custom Styles
// eslint-disable-next-line no-unused-vars
import AppStyles from "./assets/sass/main.scss";

// eslint-disable-next-line no-unused-vars
import store from "./assets/vuex/storage.js";
import ApiService from "./assets/vue/common/api.service";

// import PushService from "src/assets/vue/common/push.service";

// Different F7-Vue plugin initialization with f7 v3.0
Framework7.use(Framework7Vue);
import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);
Vue.use(require("vue-moment"));
ApiService.init();

new Vue({
  render: (h) => h(App),
  store: store
}).$mount("#app");
