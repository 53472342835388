<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Личный кабинет</div>
    </f7-navbar>
    <f7-toolbar tabbar>
      <f7-link tab-link="#tab-userProfile-1" tab-link-active>Кошелек</f7-link>
      <f7-link tab-link="#tab-userProfile-2">Категории</f7-link>
    </f7-toolbar>
    <f7-tabs animated>
      <f7-tab id="tab-userProfile-1" class="page-content" tab-active>
        <f7-block>
          <f7-list media-list inset>
            <f7-list-item title="Имя" :subtitle="user.name"> </f7-list-item>
            <f7-list-item title="Телефон" :subtitle="user.phone">
            </f7-list-item>
            <f7-list-item title="E-mail" :subtitle="user.email"> </f7-list-item>
            <!--                        <f7-list-item-->
            <!--                                title="Баланс"-->
            <!--                                :subtitle="user.purse + 'р.'">-->
            <!--                        </f7-list-item>-->
          </f7-list>
          <!--                    <div class="content-block">-->
          <!--                        <h3>Операции со счетом</h3>-->
          <!--                        <br/>-->
          <!--                        <div class="row">-->
          <!--                            <div class="col-100">-->
          <!--                                <button  v-on:click="payCash" style="background: #0aaaa0;color: white;" class="button add-cash">Пополнить</button>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <br/>-->
          <!--                        <h4>Операции по счету вы можете просмотреть на сайте <a href="http://tube-city.ru">tube-city.ru</a></h4>-->
          <!--                    </div>-->
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-userProfile-2" class="page-content">
        <f7-block>
          <div class="content-block">
            <div class="content-block-title text-medium text-bold">
              Услуги для пуша
            </div>
            <div class="list">
              <ul>
                <li v-for="service in services" :key="service">
                  <label class="item-checkbox item-content">
                    <input type="checkbox" ternary v-model="service.checked" />
                    <i class="icon icon-checkbox"></i>
                    <div class="item-inner">
                      <div class="item-title">{{ service.name }}</div>
                    </div>
                  </label>
                </li>
              </ul>
              <button
                v-on:click="editService()"
                style="background: #0aaaa0; color: white"
                class="button"
              >
                Изменить
              </button>
            </div>
          </div>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";

export default {
  name: "userProfile",
  data: () => {
    return {
      user: null,
      services: [
        // {id:1, name:'Камеры', checked: false},
        { id: 2, name: "Экстренная помощь", checked: false },
        { id: 3, name: "Вопрос юристу", checked: false },
        { id: 4, name: "Детали кузова", checked: false },
        { id: 5, name: "Шиномонтаж", checked: false },
        { id: 6, name: "Техпомощь", checked: false },
        { id: 7, name: "Эвакуатор", checked: false },
        { id: 8, name: "Аварийный комиссар", checked: false },
        { id: 9, name: "Выездная диагностика", checked: false },
      ],
    };
  },
  created: function () {
    this.user = ApiService.user();
    console.log(ApiService.auth);
    ApiService.getUserCash()
      .then(async (response) => {
        const service = await ApiService.getService();
        this.services = service.map(v => {
          return { ... v, checked: false}
        })

        this.user.purse = response;
        console.debug(this.user);
        if (JSON.parse(this.user.services) != null) {
          JSON.parse(this.user.services).forEach((valueFind) => {
            this.services.find(
              (value) => value.id === valueFind
            ).checked = true;
          });
        }
        ApiService.setLocalStorageUser(this.user);
      })
      .catch(() => {});
  },
  methods: {
    editService: function () {
      if (this.validateServices()) {
        this.$f7.dialog.preloader("Пожалуйста подождите");
        let services = [];
        this.services.forEach((value) => {
          if (value.checked == true) services.push(value.id);
        });
        ApiService.updateServices(JSON.stringify(services))
          .then(() => {
            this.$f7.dialog.close();
          })
          .catch(() => {
            this.$f7.dialog.close();
          });
      }
    },
    validateServices() {
      let check = false;
      this.services.forEach((value) => {
        if (check == false) check = value.checked;
      });
      if (check == false)
        this.$f7.dialog.alert(
          "Необходимо выбрать хотябы один вид деятельности",
          "Ошибка"
        );
      return check;
    },
    payCash() {
      this.$f7.dialog.prompt(
        "Введите сумму для пополнения?",
        "Оплата",
        (name) => {
          this.$f7router.navigate("/pay/", {
            props: {
              cash: name,
            },
          });
        }
      );
    },
  },
};
</script>

<style scoped>
</style>
